import * as React from "react";
import Link from "next/link";
import styles from "./styles.module.css";

const CompactFooter: React.FC<{}> = (props) => {
  return (
    <footer className={`${styles.footer} py-4`}>
      <div className="container">
        <div className="row">
          <div className="col mb-3 mb-md-0 col-md-auto text-center text-lg-start">
            &copy; Greener House 2023
          </div>
          <div className="col-12 col-md text-center text-lg-left text-lg-end">
            <span className="d-inline-block mb-2 mb-lg-0">
              <Link href="/legal/terms-of-service" legacyBehavior>
                <a className={styles.footer__link}>Terms of Service</a>
              </Link>
            </span>
            <span className="d-inline-block mx-3">·</span>
            <span className="d-inline-block mb-2 mb-lg-0">
              <Link href="/legal/privacy-policy" legacyBehavior>
                <a className={styles.footer__link}>Privacy Policy</a>
              </Link>
            </span>
            <span className="d-inline-block mx-3">·</span>
            <span className="d-inline-block mb-2 mb-lg-0">
              <Link href="/legal/cookie-policy" legacyBehavior>
                <a className={styles.footer__link}>Cookie Policy</a>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default CompactFooter;
