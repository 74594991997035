import * as React from "react";
import { Button } from "../Button";

export const ButtonGroup: React.FC<{
  isLoading?: Boolean;
  onClick?: (evt: any) => void;
  items: Array<string>;
}> = ({ items, onClick, isLoading = false }) => {
  let itemsList = items.map(function (value, index) {
    return (
      <div className="col-6 mb-3" key={index}>
        <Button name={value} type="button" title={value} onClick={onClick} />
      </div>
    );
  });
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        itemsList
      )}
    </>
  );
};

export default ButtonGroup;
