import * as React from "react";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import styles from "./styles.module.css";

const Nav: React.FC<{
  logoLink?: string;
  theme?: "dark" | "light";
}> = ({ logoLink = "/", theme = "light" }) => {
  return (
    <nav>
      <div
        className={`d-none d-md-block ${
          theme === "dark" ? styles.dark__theme : ""
        }`}
      >
        <DesktopNav logoLink={logoLink} />
      </div>
      <div
        className={`d-block d-md-none ${
          theme === "dark" ? styles.dark__theme : ""
        }`}
      >
        <MobileNav logoLink={logoLink} />
      </div>
    </nav>
  );
};

export default Nav;
