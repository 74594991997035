import * as Yup from "yup";
import * as React from "react";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { Heading, Button } from "../../../Shared";
import { LeadContext } from "../../../../lib/context/lead";

const answers = [
  {
    label: "Homeowner",
    value: "homeowner",
  },
  {
    label: "Tenant",
    value: "tenant",
  },
];

const OwnerOrTenant: React.FC = () => {
  const router = useRouter();
  const { setOwnerOrTenant } = React.useContext(LeadContext);
  const [loading, setLoading] = React.useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      answer: "",
    },
    validationSchema: Yup.object({
      answer: Yup.string()
        .oneOf(
          ["homeowner"],
          "We apologize, but we can only provide quotes to homeowners."
        )
        .required("Please select an answer."),
    }),
    onSubmit: (values: any) => {
      setLoading(true);
      setOwnerOrTenant(values.answer);

      router
        .push("/heat-pumps/type-of-insulation")
        .then(() => setLoading(false));
    },
  });

  return (
    <>
      <Heading
        title="Are you a homeowner or tenant?"
        variant={5}
        isBold={true}
      />
      <form onSubmit={formik.handleSubmit}>
        <div className="row my-5">
          {formik.touched.answer && formik.errors.answer ? (
            <>
              <div className="col-12">
                <div
                  className="alert alert-danger d-flex align-items-center"
                  role="alert"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-exclamation-square-fill me-3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>{" "}
                  <div>{formik.errors.answer as string}</div>
                </div>
              </div>
            </>
          ) : null}
          {answers.map((obj) => {
            return (
              <div className="col-6" key={`owner-or-tenant-${obj.value}`}>
                <div
                  onClick={() => formik.setFieldValue("answer", obj.value)}
                  className={`card shadow-sm ${
                    formik.values.answer == obj.value ? "selectedCard" : null
                  }`}
                >
                  <div className="card-body text-center">
                    <div className="form-check">
                      <input
                        onChange={formik.handleChange}
                        className="form-check-input"
                        type="radio"
                        name="answer"
                        id={`owner-or-tenant-${obj.value}`}
                        value={obj.value}
                        checked={formik.values.answer == obj.value}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`owner-or-tenant-${obj.value}`}
                      >
                        {obj.label}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Button
          type="submit"
          name="answer"
          title="Continue"
          isLoading={loading}
        />
      </form>
    </>
  );
};

export default OwnerOrTenant;
