import * as React from "react";
import Link from "next/link";
import styles from "./styles.module.css";
import Image from "next/image";

const DesktopNav: React.FC<{
	logoLink: string;
}> = ({ logoLink }) => {
	return (
		<div className="container position-relative">
			<div className="d-flex flex-fill">
				<Link href={logoLink} className={styles.nav__logo}>
					<span className={styles.nav__logo}>
						<span className={styles.nav__logo_color}>Greener</span>House.
					</span>
				</Link>
				<div className={styles.nav}></div>
				{logoLink == "/solar" ? (
					<div className="position-relative pt-3">
						<Image
							src="/scottish-power.svg"
							alt="Scottish Power logo."
							width={"235"}
							height={"40"}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default DesktopNav;
