import * as React from "react";

const WhyHeatPump: React.FC = () => {
	return (
		<div className="container mb-5">
			<div className="row gx-md-3 gx-lg-5 gy-3">
				<div className="col-12 col-md-4 col-lg-4">
					<div className="card border-light p-3 h-100 text-center">
						<div className="card-body">
							<h5 className="card-title">More Efficient</h5>
							<div className="card-text">
								A air source heat pump is 3 to 4 times more efficient than other
								boilers.
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-4 col-lg-4">
					<div className="card border-light p-3 h-100 text-center">
						<div className="card-body">
							<h5 className="card-title">Boiler Upgrade Scheme</h5>
							<div className="card-text">
								You can save £7,500 on installation costs with the government’s
								Boiler Upgrade Scheme
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-4 col-lg-4">
					<div className="card border-light p-3 h-100 text-center">
						<div className="card-body">
							<h5 className="card-title">Reduced Carbon Footprint</h5>
							<div className="card-text">
								A heat pump will cut the average person’s carbon footprint by
								42%
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhyHeatPump;
