import * as React from "react";

export const Checkbox: React.FC<{
  name: string;
  isChecked: boolean;
  onChange: (evt: any) => void;
}> = ({ name, onChange, isChecked }) => {
  return (
    <>
      <input
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={isChecked}
      />
    </>
  );
};

export default Checkbox;
