import * as React from "react";
import Link from "next/link";
import styles from "./styles.module.css";
import Image from "next/image";

const MobileNav: React.FC<{
	logoLink: string;
}> = ({ logoLink }) => {
	return (
		<div className="container">
			<div className="d-flex flex-fill flex-wrap justify-content-center">
				<Link href={logoLink} className={styles.nav__logo}>
					<span className={styles.nav__logo}>
						<span className={styles.nav__logo_color}>Greener</span>House.
					</span>
				</Link>

				{logoLink == "/solar" ? (
					<div className="position-relative pt-3 ps-2">
						<Image
							src="/scottish-power.svg"
							alt="Scottish Power logo."
							width={"240"}
							height={"58"}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default MobileNav;
