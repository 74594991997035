import * as React from "react";

type FormSelect = {
  name: string;
  value: number | string | undefined;
  optionsList: Array<string>;
  handleChange: (evt: React.SyntheticEvent) => void;
  placeholder: string;
};

export const FormSelect: React.FC<FormSelect> = ({
  name,
  value,
  optionsList,
  handleChange,
  placeholder,
}) => {
  return (
    <select
      className="form-select pt-3 pb-3 ps-4 pe-5 input"
      value={value}
      name={name}
      onChange={handleChange}
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {optionsList.map((item, index) => {
        return (
          <option key={index} value={index}>
            {item}
          </option>
        );
      })}
    </select>
  );
};

export default FormSelect;
