import * as React from "react";
import styles from "./styles.module.css";

export const Heading: React.FC<{
  title: string;
  isBold: boolean;
  variant: number;
}> = ({ title, isBold, variant }) => {
  if (variant < 1 || variant > 6) {
    throw "Invalid variant supplied.";
  }

  return (
    <h1 className={`${isBold ? styles.bold : ""} ${styles["h" + variant]}`}>
      {title}
    </h1>
  );
};

export default Heading;
