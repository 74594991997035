import Head from "next/head";
import * as React from "react";
import Nav from "../components/Nav";
import { Heading } from "../components/Shared";
import CompactFooter from "../components/Footer";
import WhyHeatPump from "../components/WhyHeatPump";
import OwnerOrTenant from "../components/Questions/HeatPump/OwnerOrTenant";

const Home: React.FC<{}> = () => {
  return (
    <div className="h-100 d-flex flex-column">
      <Head>
        <title>Find the heat pump for your home. | Greener House</title>
      </Head>
      <Nav logoLink="/" theme="dark" />
      <div className="flex-grow-1 align-items-center bg-blue">
        <div className="container">
          <section className="container-sm-fixed text-center pt-5 pb-5 mx-auto">
            <div>
              <Heading
                title="Find the heat pump for your home."
                isBold={true}
                variant={2}
              />
            </div>
          </section>
          <section className="container-sm-fixed p-5 mx-auto bg-white shadow-sm border mb-5">
            <OwnerOrTenant />
          </section>
          <section className="container-sm-fixed text-center pt-5 pb-5 mx-auto">
            <div>
              <Heading
                title="Why should you invest in a heat pump?"
                isBold={false}
                variant={3}
              />
            </div>
          </section>
          <WhyHeatPump />
        </div>
      </div>
      <CompactFooter />
    </div>
  );
};

export default Home;
